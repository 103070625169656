<template>
       <v-menu
          offset-y
          min-width="250"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              dark
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-hexagon-multiple</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon>mdi-hexagon-multiple</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>Betrieb wählen</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>
          <v-list>
            <v-list-item
              v-for="(item, id) in companies"
              :key="id"
              link
              @click="changeCompany(item)"
            >
              <v-list-item-title>
                    {{ item.name }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
</template>

<script>

export default {

    props:{
        companies: {
        required: true,
        type: Array,
        default: () => []
      }
    },

    data () {
        return {
            items:[
                { title: 'Click Me' },
                { title: 'Click Me' },
                { title: 'Click Me' },
                { title: 'Click Me 2' },
            ],
 
        }
    },

    computed: {


    },
    methods:{

        changeCompany: function(item){
          console.debug('changeCompany', item);
          this.$emit('click', item)
        }


    },
}
</script>


            