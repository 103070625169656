<template>
     <v-navigation-drawer
      v-model="drawer"
      :mini-variant="mini"
      width="300"
      clipped
      app
    >  
      <v-list color="green lighten-4">
        <v-list-item>
          
            <v-icon large>{{option.title.icon}}</v-icon>
            <v-list-item-title class="text-h6 ml-6">
              {{option.title.caption}}
            </v-list-item-title>
          
        </v-list-item>
      </v-list>

      <v-divider></v-divider>


      <template v-for="item in items" ,:key="item.id" >

        <v-list-item
            v-if="item.childcount == 0"
          v-model="item.model"          
          :key="item.id"
          link
          @click="currentSelection = item; selectionChanged(item.id); pushRoute(item)"
        >
            <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title v-text="item.caption"></v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-list-group v-else
          v-model="item.model" 
          :key="item.id"
          link
          no-action
          @click="currentSelection = item;selectionChanged(item.id)"
        >
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.caption }}</v-list-item-title>           
          </template>

          <template v-for="child in item.childs" ,key="child.caption" >
            <v-list-item v-if="child.childcount == 0"
                v-model="child.model"
                :to="child.route"
                :key="child.id"
                link
                @click="currentSelection = child; selectionChanged(item.id)"
            >
                <v-list-item-icon>
                    <v-icon small>{{child.icon}}</v-icon>
                </v-list-item-icon>
                <v-list-item-title v-text="child.caption"></v-list-item-title>
            </v-list-item>  

            <v-list-group v-else
                v-model="child.model"
                :key="child.id"
                link
                @click="currentSelection = child;selectionChanged(item.id)"
              >
                <template v-slot:activator v-if="child.childcount > 0">
                  <v-list-item-icon>
                    <v-icon>{{ child.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ child.caption }}</v-list-item-title>           
                </template>

                <!-- <template v-for="child2 in child.childs" ,:key="child2.title" >
                    <v-list-item
                        :to="child2.route"
                        :value="false"
                        :key="child2.id"
                        link
                        @click="currentSelection = child2; selectionChanged(item.id)"
                        >
                        <v-list-item-icon class="ml-8">
                          <v-icon small>child2.icon</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title v-text="child2.title"></v-list-item-title>
                      </v-list-item>  
                </template> -->

                <!-- <template v-for="action in child.actions" ,:key="action.title" >
                  <v-list-item 
                    :to="child.route +'/'+ action.name"
                    :value="false"
                    :key="action.id"
                    
                    link
                    @click="currentSelection = item;"
                    >
                    <v-list-item-icon class="ml-8">
                      <v-icon small>{{action.icon}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title v-text="action.title"></v-list-item-title>
                  </v-list-item>  
                </template>  -->

            </v-list-group>


          </template>

          <!-- <template v-for="action in item.actions" ,:key="action.title" >

            <v-list-item 
              :to="item.route +'/'+ action.name"
              :key="action.id"              
              link
              @click="currentSelection = item;"
            >
                <v-list-item-icon class="ml-4">
                    <v-icon small>{{action.icon}}</v-icon>
                </v-list-item-icon>
                <v-list-item-title v-text="action.title"></v-list-item-title>
                </v-list-item>  
          </template> -->
 



        </v-list-group>
     </template>

    </v-navigation-drawer>
</template>

 <script>
// import AxiosService from "../../services/axios.service";
import EventBus from '../../services/event-bus.service';

export default {

    components: {
    },

    props: {

        option:{
            type: Object,
            // required: true,
            default () {
                return {
                    title:{
                        caption: "Title",
                        icon:""
                    },
                }
            }
        },

        menu:{
            type: Array,
            // required: true,
            default () {
                return [{ caption: 'Home', icon: 'mdi-home', actions:[] }]                
            }
                
            
        },

    },

    data () {
        return { 
            drawer: true,
            mini: false, 
            currentSelection: undefined,
            items:[]
            
        }
    },

    computed: {

        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },

        currentUser() {
        return this.$store.state.auth.user;
        },

        // menu(){
        //     return this.$store.state.app.menu;
        // },

    
    },

    methods:{

        pushRoute:function(item){
          console.debug('pushRoute', item);
          this.$router.push({ path:  item.route })
        //({ path: `/user/${userId}` }) // -> /user/123

        },

        selectionChanged: function(id){
            console.log(this.currentSelection.route)
            for(var key in this.items)
            {
              if(this.items[key].id != id)
                this.items[key].model = false;
            }

        },


    },

    watch: { 
        // company() { // watch it
        //     console.debug('Prop changed: ', this.company);
        //     if(this.company.id)
        //         this.refreshNavbar();
        // },

        menu(newVal){
            this.items = newVal
        }
    },
    
    mounted() {

        //this.refreshNavbar();
        console.debug('mounted', this.company);
        this.items = this.menu
        if(this.company)
            this.refreshNavbar();

        EventBus.$on('toggleMenu', (msg) =>{
        
            console.debug('toggle recived', msg)
            this.drawer = !this.drawer;

        });

    },

}
</script>
