<template>


  <v-layout>
     
    <appbar></appbar>
    <navileft :menu="menu" :option="option.navileft"></navileft>    

    <!-- Sizes your content based upon application components -->
    <v-main>
        <!-- Provides the application the proper gutter -->
        <!-- <v-container fluid fill-height> -->
        <v-container fluid>
          <router-view :key="$route.fullPath"/>
        </v-container>
    </v-main>

    <v-footer app>
        <!-- -->
    </v-footer>
  </v-layout>
</template>

<script>
import { helpers } from 'gmap-vue';
const { googleMapsApiInitializer } = helpers;
import appbar from '../components/appbars/AppBarCompany.vue'
import navileft from '../components/navbars/NavigationLeft.vue'

  export default {
    name: 'Company',
    message:'',

    components: {
      appbar,
      navileft,     
    },

    data () {
        return {
            option:{
                navileft:{
                    title:{
                        caption: "Company",
                        icon: "", 
                    }
                }
            }
        }
    },    

    computed: {

        user(){
            return this.$store.state.auth.user
        },

        routeParams(){
            return this.$route.params
        },

        company() {
            return this.$store.state.app.actCompany.meta
        },

        menu(){
            console.debug('menu', this.$store.state.app.menu)
            return this.$store.state.app.menu;
        },

        apiKeys() {
            return this.$store.state.app.apikeys
        },        
    },

    methods:{ 

        initApiKey(){
            console.debug('initApiKey', this.apiKeys)
            if(this.apiKeys['GoogleMaps']){
                googleMapsApiInitializer(
                    {
                        key: this.apiKeys['GoogleMaps'].api_key,
                        libraries: 'places,drawing,geometry ',
                    },
                    false
                );
            }
            //else //todo
        },

        // getCompanyById(uid){
        //     let result = [this.user.linkedCompanies[0]]
        //     if(uid)
        //         result = this.user.linkedCompanies.filter(obj => {return obj.uid === uid})

        //     if(result.length < 1)
        //         return {id:undefined}
        //     else            
        //         return result[0]; 
        // }, 

        // setCompanyById(uid){
        //     console.debug('setCompanyById', uid)
        //     const res = this.getCompanyById(uid)
        //     if(res)
        //         this.$store.commit("setActCompany", {meta:res});
        // },

        // refresh(){            
        //     const comp = this.company
        //     console.debug('refresh', comp);    
        //      if(comp){
        //         this.option.navileft.title.caption = comp.name
        //         this.$store.dispatch("getAccess", {path:"api/company/access", param:{id:comp.id, content:"fields"}})    
        //         this.$store.dispatch("getApiKeys", {path:"api/company/apikeys", param:{id:comp.id}})    
        //         this.$store.dispatch("getMenu", {path:"api/company/menu", param:{id:comp.id}})    
        //     }
        // },

    },   

    mounted() {
        console.log('mounted', this.$route.params) 
        if(!this.$route.params.uid || !this.company.id){
            console.log('push', this.$route.params) 
            this.$router.push({ name: 'LoadCompany'}) 
            return;
        }
        this.initApiKey()    
        this.option.navileft.title.caption = this.company.name    
    },

    watch: { 
        // company(newVal) { // watch it
        //     console.debug('Prop changed: company', newVal); 
        //     // this.option.navileft.title.caption = newVal.name
        //     //     // if(newVal.uid) 
        //         //     window.location.reload()  
        //     // this.refresh()
        // },
        
        // routeParams(newVal) { // watch it
        //     console.debug('Prop changed: route params', newVal.uid);
        //     // this.setCompanyById(this.$route.params.id)
        //     // this.refresh()
           

        // },

        apiKeys(newVal) { // watch it
            console.debug('Prop changed: apiKeys', newVal); 
            this.initApiKey()
        },

    }    

}
</script>
